import React from 'react';
import Typography from "@material-ui/core/es/Typography";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";

const styles = makeStyles((theme: Theme) => ({
    base: {
        zIndex: 1250,
        color: 'rgb(10,10,10)',
        whiteSpace: "pre-line"
    },
    h1: {
        fontFamily: "'Montserrat', sans-serif !important",
        fontSize: "75px !important",
        [theme.breakpoints.down('md')]: {
            fontSize: "50px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "32px !important"
        },
    },
    h2: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "40px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "42px !important"
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "38px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "28px !important"
        },
    },
    h3: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "32px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "28px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "28px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "22px !important"
        },
    },
    h4: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "32px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "26px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px !important"
        },
    },
    h5: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "26px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px !important"
        },
    },
    h6: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "22px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "18px !important"
        },
    },
    body1: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "20px !important",
        [theme.breakpoints.down('md')]: {
            fontSize: "18px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "16px !important"
        },
    },
    body2: {
        letterSpacing: "-0.2px !important",
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "16px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px !important"
        },
    },
    subtitle2: {
        fontFamily: "'stolzl', sans-serif !important",
    },
    subtitle1: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "11px !important",
        letterSpacing: "0 !important",
        lineHeight: "1.2 !important",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10px !important"
        },
    },
    overline: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "12px !important",
        letterSpacing: "1.4px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "10px !important"
        }
    },
    caption: {
        fontFamily: "'stolzl', sans-serif !important",
        fontSize: "16px !important",
        letterSpacing: "0 !important",
        lineHeight: "1.2",
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px !important"
        },
    },
    xl: {
        fontSize: "56px !important",
        textTransform: "uppercase"
    },
    navigation: {
        alignItems: "center",
        height: "100%",
        display: "flex",
        // padding: "0 24px"
    },
    regular: {
        fontWeight: 300,
    },
    medium: {
        fontWeight: 400,
    },
    bold: {
        fontWeight: 500,
    },
    black: {
        fontWeight: 700,
    },
}));

interface TextProps extends React.ComponentPropsWithoutRef<"span"> {
    variant?: Variant,
    component?: any,
    children: React.ReactNode,
    style?: React.CSSProperties,
    regular?: boolean,
    medium?: boolean,
    bold?: boolean,
    black?: boolean,
    xl?: boolean,
    selectable?: boolean,
    noWrap?: boolean
    id?: string | undefined,
    dataScroll?: boolean
    dataScrollSpeed?: string
    dataScrollPosition?: string
}

function Text (props: TextProps) {

    const classes = styles();
    const { variant, noWrap, children, style, medium, black, selectable=true, onClick, onMouseEnter, bold, onMouseLeave, component, id, regular, className, dataScroll, dataScrollSpeed, dataScrollPosition, xl } = props;

    return (
        <Typography component={component}
                    data-scroll={dataScroll}
                    data-scroll-speed={dataScrollSpeed}
                    data-scroll-position={dataScrollPosition}
                    id={id}
                    onClick={onClick}
                    variant={variant}
                    noWrap={noWrap}
                    className={
                        classNames(
                            { [classes.base] : true},
                            { [classes[variant]]: true },
                            { [classes.medium]: medium },
                            { [classes.black]: black },
                            { [classes.bold]: bold },
                            { [classes.regular]: regular },
                            { [classes.xl]: xl },
                            { [className]: className!==null }
                        )
                    }
                    style={{ userSelect: selectable ? "unset" : "none", ...style }}
                    onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}
                    classes={{
                        root: classes[variant],
                        overline: classes.overline,
                        h1: classes.h1,
                        h2: classes.h2,
                        h3: classes.h3,
                        h4: classes.h4,
                        h5: classes.h5,
                        body1: classes.body1,
                        body2: classes.body2,
                        caption: classes.caption,
                    }}

        >
            {children}
        </Typography>
    );
}

export default Text ;